// 审核记录

<template>
  <div class="pre">
    <P>审核记录</P>
    <div class="wrap">
      <div class="record" v-for="(item, i) in recordList" :key="i">
        <div class="fl">
          <p class="name">
            <span>{{ item.patient_name }}</span>
            <!-- <span>{{ item.category_name }}</span> -->
          </p>
          <p class="time">{{ item.add_time }}</p>
        </div>
        <div class="info">
          <p>{{ item.status_text }}</p>
          <p @click="showDetail(item)">查看详情</p>
        </div>
      </div>
      <div class="page" v-if="showPage">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next"
          :page-size="15"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getDoctorAuditList } from "@/request/personnel";

export default {
  props: ["recordList", "type", "showPage", "total"],
  data() {
    return {
      defalutImgUrl: require("@/assets/image/Furtherconsultation_Defaulthead@2x.png")
    };
  },
  methods: {
    changePage(n) {
      this.$emit("changePage", n)
    },
    showDetail(item) {
      this.$emit("changeState", 7, item.p_code);
    }
  }
};
</script>

<style lang="less" scoped>
.pre {
  width: 1200px;
  margin: 0 auto;
  min-height: 700px;
  font-size: 16px;
}
.pre > p {
  background: #fff;
  font-size: 20px;
  color: #4eeec8;
  height: 70px;
  border: 1px solid #ccc;
  border-bottom: 0px;
  line-height: 70px;
  padding-left: 50px;
}
.wrap {
  border: 1px solid #ccc;
  padding-bottom: 30px;
  background: #fff;
  min-height: 700px;
}
.record {
  font-size: 12px;
  padding-left: 30px;
  padding: 25px 0px;
  border-bottom: 1px solid #ccc;
}
.record .fl {
  padding-left: 50px;
  color: #424224;
  .name {
    margin-bottom: 15px;

    span:nth-child(1) {
      font-size: 18px;
      margin-right: 15px;
      color: #4eeec8;
    }
  }
}
.record:after {
  content: "";
  clear: both;
  display: block;
}
.record img {
  border-radius: 50%;
}
.info {
  float: right;
  margin-right: 100px;
  cursor: pointer;
  p:nth-child(1) {
    color: #999;
    margin-bottom: 10px;
  }
  p:nth-child(2) {
    font-size: 16px;
    color: #4eeec8;
  }
}
.page {
  text-align: center;
  margin: 25px 0px;
}
</style>